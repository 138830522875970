* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500&display=swap");

@font-face {
  font-family: "Lack";
  src: local("Lack Regular"), local("Lack-Regular"),
    url("./assets/fonts/Lack-Regular.woff2") format("woff2"),
    url("./assets/fonts/Lack-Regular.woff") format("woff"),
    url("./assets/fonts/Lack-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

a {
  text-decoration: none;
}

html {
  color: #ffffff;
  font-family: "Lack", sans-serif;
  font-weight: 400;
  font-size: 16px;
  background-color: #111111;
}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #3c3c3c 100%);
}
body::before {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: hard-light;
  background: url("./assets/bg_noise.png");
  background-repeat: repeat;
  background-position: 50% 0;
}

.img {
  max-width: 100%;
  height: auto;
}

.row {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.orange {
  color: #ef4f07;
}

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;

  z-index: 3;

  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  text-align: center;
  vertical-align: middle;
  font-family: "Lack", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2em;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  padding: 20px 0;

  color: #ffffff;

  border: none;
  background: #225513;

  border-radius: 8px;
}

.button_rectangle {
  border-radius: 8px;
}

.button_tg {
  background-color: #01a1fd;
}

.button:active {
  transform: scale(0.97);
}
.button:hover {
  transform: scale(1.05);
}

.button:disabled {
  opacity: 0.7;
  transition: none;
}
.button:disabled:hover {
  transform: scale(1);
  transition: none;
}
.button:disabled:active {
  transform: scale(1);
}

.mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  .mobile {
    display: initial;
  }
  .desktop {
    display: none !important;
  }
}

.loader {
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
