.checklist {
  overflow: hidden;
  height: 100vh;
}

.checklist__wrapper {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
}

.checklist__logo {
  text-align: left;
  margin-bottom: 60px;
}
.mobile.checklist__logo {
  display: none;
}

.checklist__main {
  margin: 0 72px;
  margin-top: 100px;
  margin-right: 0;
}

.checklist__title {
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.checklist__title div + div {
  margin-top: 22px;
}

.checklist__subtitle {
  display: block;

  margin-top: 44px;
  margin-bottom: 60px;

  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.78px;
}

.checklist__arrows {
  margin-left: 100px;
}

.checklist__leftside {
  text-align: left;
  width: 65%;
}

.checklist__rightside {
  position: relative;
}

.checklist__rightside__decor {
  z-index: 2;
  content: " ";
  position: absolute;
  bottom: 0;
}

.checklist__rightside__rect {
  content: " ";
  position: relative;

  width: 100%;
  max-width: 465px;

  height: 50vh;

  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 2%, #fff 300%);

  mix-blend-mode: soft-light;
}

.checklist__rightside__rect_inner {
  z-index: 1;
  overflow: hidden;
  content: " ";
  position: relative;

  width: 660px;
  max-width: 660px;

  height: calc(50vh - 50px);

  bottom: -50px;
  margin-left: -65px;

  border: 1px solid #ef4f07;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom: none;
}

.checklist__rightside__img-container {
  position: relative;
  z-index: 5;
  width: 100%;
  max-width: 650px;
}

.checklist__finish .checklist__rightside__img-container .img {
  margin-left: 0 !important;
}

.checklist__finish {
  margin-right: 72px;
}

.checklist__rightside__img-container .img {
  margin-left: 20%;
}

.checklist__rightside__top-title {
  text-align: left;
  top: 0;

  position: absolute;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.48px;
}

.checklist__rightside__bottom-title {
  position: absolute;
  bottom: 0;
  right: 0;
}

.checklist__rightside__bottom-title span {
  display: block;
}

.checklist .mobile.button {
  display: none;
}

.mobile.checklist__rightside__bottom-title {
  display: none;
}

.checklist__form {
  width: 100%;
  max-width: 440px;

  margin: 0 auto;
  margin-top: 100px;
  padding: 24px 72px;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ef4f07;

  background: #1e1e1e;
}

.checklist__form .input {
  width: 100%;
  padding: 15px 24px;
  border: 1px solid #808080;
  background: transparent;
  color: #ffffff;
}

.checklist__form .decor__free {
  align-self: flex-start;
  padding: 10px 20px;
  border-radius: 18.5px;
  background: #ef4f07;
  color: #ffffff;

  text-align: center;
  font-family: "Geometria";
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.12px;
  text-transform: uppercase;
}

.checklist__form .form__subtitle {
  margin-top: 28px;
  margin-bottom: 8px;
  text-align: center;
  font-family: "Geometria";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
}

.checklist__form .form__title {
  margin-bottom: 24px;
  font-family: "Geometria";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  text-align: center;
}

.checklist__form .input + .input {
  margin-top: 16px;
}

.checklist__form .form__policy {
  margin-top: 12px;
  margin-bottom: 28px;
  text-align: center;
  font-family: Geometria;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1px;
  
}

.checklist__form .form__policy a {color:#ef4f07;}

.checklist__finish .button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checklist__rightside__top-title {
  right: 40px;
}

.checklist__finish .checklist__rightside {
  background: transparent;
}

.checklist__finish .checklist__rightside::before {
  background: transparent;
}

@media screen and (max-width: 1440px) {
  .checklist__title {
    font-size: 55px;
  }
  .checklist__wrapper {
    max-width: 1400px;
  }
  .checklist__main .checklist__wrapper {
    max-width: 100%;
  }
  .checklist__arrows {
    margin-left: 100px;
  }
}

@media screen and (max-width: 1280px) {
  .checklist__wrapper {
    max-width: 1200px;
  }
  .checklist__main .checklist__wrapper {
    max-width: 100%;
  }

  .checklist__leftside {
    width: 60%;
  }
  .checklist__main {
    margin-left: 30px;
  }
  .checklist__title {
    font-size: 40px;
  }
  .checklist__title div + div {
    margin-top: 16px;
  }
  .checklist__subtitle {
    position: relative;
    margin: 30px 0 60px;

    font-size: 18px;
  }

  .checklist__rightside__img-container {
    max-width: 450px;
  }
}

@media screen and (max-width: 960px) {
  .checklist__main {
    margin-top: 10px;
    margin-right: 30px;
  }
  .checklist__leftside {
    width: auto;
  }
  .mobile.checklist__logo {
    display: block;
    position: relative;
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .desktop.checklist__logo {
    display: none;
  }
  .checklist .mobile.button {
    display: block;
    margin-top: 20px;
  }
  .checklist .desktop.button {
    display: none;
  }
  .checklist .row {
    flex-direction: column;
    align-items: center;
  }

  .checklist__title {
    margin-top: 20px;
  }

  .checklist__subtitle {
    position: relative;
    width: 100%;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .checklist__finish .checklist__subtitle br {
    display: initial;
  }


  .checklist__arrows {
    margin: 0;
  }

  .mobile.checklist__arrows {
    position: absolute;
    transform: rotate(90deg);
  }

  .checklist__arrows {
    right: -10px;
  }

  .checklist .button {
    display: block;
    margin: 0 auto;
  }

  .checklist__rightside {
    background: transparent;
    margin-top: 40px;
  }

  .checklist__rightside::before {
    background: transparent;
  }

  .checklist__leftside .buttton {
    display: none;
  }

  .checklist__rightside__bottom-title {
    display: none;
  }
  .mobile.checklist__rightside__bottom-title {
    display: block;
    bottom: 0;
  }

  .checklist__rightside__img-container {
    max-width: 500px;
    margin: 0 auto;
  }
  .checklist__rightside__img-container .img {
    margin: 0;
  }
}

@media screen and (max-width: 760px) {
  .checklist__title {
    font-size: 35px;
  }

  .checklist__arrows {
    right: 10px;
  }
  .checklist__rightside__top-title {
    right: 0;
  }

  .checklist__finish .checklist__rightside__top-title {
    display: none;
  }
  .checklist__rightside__img-container {
    max-width: 400px;
  }
}

@media screen and (max-width: 480px) {
  .checklist__title {
    font-size: 26px;
  }
  .checklist__subtitle {
    font-size: 12px;
  }
  .checklist__arrows {
    top: 45px;
  }
  .checklist__leftside {
    align-self: flex-start;
    width: 100%;
  }
  .checklist__form {
    padding-left: 20px;
    padding-right: 20px;
  }
  .checklist__form .form__title {
    font-size: 14px;
  }
  .checklist__finish .checklist__title {
    font-size: 32px;
  }

  .checklist__form .input {
    font-size: 16px;
  }

  .checklist__rightside__img-container {
    max-width: 90%;
  }
}

@media screen and (max-width: 375px) {
  .checklist__main {
    margin-left: 20px;
    margin-right: 20px;
  }
  .checklist__title {
    font-size: 22px;
  }
  .checklist__subtitle {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
}
